import { getCurrencySymbol, formatCurrency } from '@angular/common';
import { Package, Availability } from '../models/venues.model';
import { getISODay } from 'date-fns/getISODay';
import { format as _format } from 'date-fns/format';
import { isBefore } from 'date-fns/isBefore';
import { isAfter } from 'date-fns/isAfter';
import { parse } from 'date-fns/parse';
import { isSameDay } from 'date-fns/isSameDay';

import { fromZonedTime } from 'date-fns-tz';
import { toZonedTime } from 'date-fns-tz';
import { format as _format_tz } from 'date-fns-tz'

//import * as moment from 'moment-timezone';
////import * as moment from 'moment';

const applyDiscount = (promo: any, price: number): number => {
    if (+promo.promo_fixed) return price - +promo.promo_fixed;
    if (+promo.promo_percent) return price * (100 - +promo.promo_percent) / 100;
    if (+promo.promo_pp) return +promo.promo_pp;

    return price;
};

export const format = (num: number, currency: string): string => {
    const symbol = getCurrencySymbol(currency, 'narrow');

    return formatCurrency(num, 'en-GB', symbol, currency);
};

export const filterOptions = (pack: Package, date?: Date, session?: Availability, message?: string): any[] => {
    const price = +pack?.price;

    if (!pack || !pack.price_options || !pack.price_options.length) {
        return [{ name: 'Participants', qty_requested: 0, price, _price: price }];
    }

    let dayNum: number;

    if (date) dayNum = date.getDay() - 1;
    if (dayNum === -1) dayNum = 6;

    //console.log('sess',session?.price_options);
    //console.log('pack',pack.price_options)

    let has_mins = false;

    let options = (session?.price_options??[]).filter(item => {
        const avail = { day: true, session: true, start_date: true, end_date: true };

        if (item.dow && item.dow.length && date) {
            avail.day = item.dow.charAt(dayNum) !== '-';
        }

        if(item._min_pax !== undefined){
            has_mins = true;
        }
        
        if (item.start_date && date){
            let start_date = parse(item.start_date,'yyyy-MM-dd', new Date());
            avail.start_date = isBefore(start_date, date) || isSameDay(start_date, date);

        }
        if (item.end_date && date){
            let end_date = parse(item.end_date,'yyyy-MM-dd', new Date());
            avail.end_date = isAfter(end_date, date) || isSameDay(end_date, date);
        }

        if (item.sessions && item.sessions.length && session && session.session_id) {
            avail.session = !!item.sessions.find(val => (val.session_id || val.id) === session.session_id);
        }

        return avail.day && avail.session && avail.start_date && avail.end_date;

    });

    if(!has_mins){
        options = [];
    }

    if(options.length == 0){
        options = pack.price_options.filter(item => {
            const avail = { day: true, session: true, start_date: true, end_date: true };

            if (item.dow && item.dow.length && date) {
                avail.day = item.dow.charAt(dayNum) !== '-';
            }
            
            if (item.start_date && date){
                let start_date = parse(item.start_date,'yyyy-MM-dd', new Date());
                avail.start_date = isBefore(start_date, date) || isSameDay(start_date, date);

            }
            if (item.end_date && date){
                let end_date = parse(item.end_date,'yyyy-MM-dd', new Date());
                avail.end_date = isAfter(end_date, date) || isSameDay(end_date, date);
            }

            if (item.sessions && item.sessions.length && session && session.session_id) {
                avail.session = !!item.sessions.find(val => (val.session_id || val.id) === session.session_id);
            }

            return avail.day && avail.session && avail.start_date && avail.end_date;

        });
    }

    // if (message) console.log(message, session.session_name, options, dayNum);

    if (options.length) {
        return options.map(item => ({
            ...item,
            qty_requested: 0,
            price: item.price || price,
            _price: item.price || price
        }));
    }

    return [{ name: 'Participants', qty_requested: 0, price, _price: price }];
};

export const getOptionsMinMax = (currency: string, pack: Package, date?: Date, session?: Availability): {
    price: string, min: number, max: number
} => {
    const prices = filterOptions(pack, date, session).reduce((acc, opt) => {
        let alts = [];

        if (opt.alts) alts = opt.alts.filter(item => item.price).map(alt => +(alt.price_calc || alt.price));

        acc = [...acc, ...alts, +(opt.price_calc || opt.price)];

        return acc;
    }, []);

    const min = Math.min(...prices);
    const max = Math.max(...prices);

    return {
        price: min === max ? format(min, currency) : format(min, currency) + ' - ' + format(max, currency),
        min,
        max
    };
};

export const getParameterByName = (name: string, url?: string) => {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');

    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
    const results = regex.exec(url);

    if (!results || !results[2]) return null;

    return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const applyPromo = (promo, date, venue, pack, session, priceOptions, qty) => {
    if (!promo) {

        if(! priceOptions || priceOptions.length == 0){
            pack._price_calc = null;
            pack.price_calc = null;
        }

        return priceOptions.map(item => {
            if (item.alts) item.alts = item.alts.map(val => ({ ...val, price_calc: null }));

            return { ...item, price_calc: null, _price_calc: null };
        });
    }

    if (!+promo.bookings) return 'Promocode is not valid for bookings';
    
    //const eventDate = moment(date);
    let eventDate = date;
    if(typeof date == 'string'){
        if(date.indexOf('-')){
            eventDate = parse(eventDate,'yyyy-MM-dd', new Date())
        }else if(date.length == 8)(
                eventDate = parse(eventDate,'yyyyMMdd', new Date())
        )

    }

    if (promo.lead && session.start_time) {
        //const userTime = moment().tz(venue.timezone);
        const userTime = fromZonedTime(new Date(), venue.timezone)
        //const sessionTime = moment.tz(`${eventDate.format('YYYY-MM-DD')} ${session.start_time}`, 'YYYY-MM-DD HH:mm', venue.timezone).subtract(+promo.lead, 'minutes');
        const sessionTime = toZonedTime(parse(`${format(eventDate,'yyyy-MM-dd')} ${session.start_time}`, 'yyyy-MM-dd HH:mm', new Date()), venue.timezone);

        if (isBefore(sessionTime, userTime)) return 'Promocode is invalid lead time';
    }

    if (
        (promo.venue_id && venue.venue_id !== promo.venue_id)
        || (promo.venue_ids && promo.venue_ids.length && !promo.venue_ids.includes(venue.venue_id))
    ) return 'Promocode is not valid for this venue';

    if (
        (promo.activity_id && pack.activity_id !== promo.activity_id)
        || (promo.activity_ids && promo.activity_ids.length && !promo.activity_ids.includes(pack.activity_id))
    ) return 'Promocode is not valid for this activity';

    if (
        (promo.package_id && pack.package_id !== promo.package_id)
        || (promo.package_ids && promo.package_ids.length && !promo.package_ids.includes(pack.package_id))
    ) return 'Promocode is not valid for this package';

    if (
        (promo.session_id && session.session_id !== promo.session_id)
        || (promo.session_ids && promo.session_ids.length && !promo.session_ids.includes(session.session_id))
    ) return 'Promocode is not valid for this session';

    const redemptionFrom = promo.start_date ? parse(promo.start_date,'yyyy-MM-dd', new Date()) : null;
    const redemptionTo = promo.end_date ? parse(promo.end_date,'yyyy-MM-dd', new Date()) : null;
    const eventFrom = promo.start_date_event ? parse(promo.start_date_event,'yyyy-MM-dd', new Date()) : null;
    const eventTo = promo.end_date_event ? parse(promo.end_date_event,'yyyy-MM-dd', new Date()) : null;
    //const weekday = moment(date).isoWeekday() - 1;
    const weekday = getISODay(date - 1)

    let now = new Date()

    let date_format = 'dd MMM yyyy';

    if (promo.dow && promo.dow.charAt(weekday) === '-') return 'This promocode is not valid on this day of the week';
    if (promo.min_pax && +promo.min_pax > qty) return 'This promocode is not valid on this group size';
    if (redemptionFrom && isBefore(now, redemptionFrom) )return `This promocode is valid from ${_format(redemptionFrom, date_format)}`;
    if (redemptionTo && isAfter(now,redemptionTo)) return `This promocode is valid until ${_format(redemptionTo, date_format)}`;
    if (eventFrom && isBefore(eventDate, eventFrom)) return `This promocode is valid for bookings after ${_format(eventFrom, date_format)}`;
    if (eventTo && isAfter(eventDate, eventTo)) return `This promocode is valid for bookings before ${_format(eventTo, date_format)}`;


    if (promo.exclusions && promo.exclusions.length) {
        const eventDateStr = format(eventDate,'yyyy-MM-dd');
        const eventDateDay = format(eventDate,'dddd');

        const excluded = promo.exclusions.reduce((acc, item) => {
            if (acc) return acc;

            return item.date === eventDateStr || item.days.includes(eventDateDay);
        }, false);

        if (excluded) return `This promo is not valid on ${format(eventDate,'dd MMM yyyy')}`;
    }

    const maxDiscount = +promo.max_discount;
    const total = priceOptions.reduce((acc, item) => acc + item.price * item.qty_requested, 0);

    if(! priceOptions || priceOptions.length == 0){
        pack._price_calc = applyDiscount(promo, pack.price);
        pack.price_calc = applyDiscount(promo, pack.price);
    }

    let priceOptionsCalc = priceOptions.map(item => {
        if (item.alts) item.alts = item.alts.map(val => ({ ...val, price_calc: applyDiscount(promo, 
            +(val.price == '' || val.price == null ? pack.price : val.price)
        ) }));

        return {
            ...item,
            price_calc: applyDiscount(promo, +(item.price == '' || item.price == null ? pack.price : item.price)),
            _price_calc: applyDiscount(promo, +(item.price == '' || item.price == null ? pack.price : item.price))
        };
    });

    const discount = total - priceOptionsCalc.reduce((acc, item) => acc + item.qty_requested * item.price_calc, 0);

    if (discount > maxDiscount && maxDiscount > 0) {
        const maxPromo = { promo_percent: maxDiscount / total * 100 };

        priceOptionsCalc = priceOptions.map(item => {
            if (item.alts) item.alts = item.alts.map(val => ({ ...val, price_calc: applyDiscount(maxPromo, +val.price) }));

            return {
                ...item,
                price_calc: applyDiscount(maxPromo, +item.price),
                _price_calc: applyDiscount(maxPromo, +item._price)
            };
        });
    }

    return priceOptionsCalc;
}
