<p *ngIf="availStatus === 'limited'" class="gn-text-white gn-m-0 gn-font-semibold gn-text-xs gn-rounded gn-uppercase gn-leading-none gn-p-1 gn-bg-limited"
    [tooltip-class]="'booking-tooltip'"
    [tooltip]="'Availability on the selected session is going fast. Spaces are reserved on a first come first served basis.'"

>{{availMessage}}</p>
<form [formGroup]="form" (ngSubmit)="book()" class="gn-w-full" #groupDetailsEl>
    <div class="gn-w-full gn-flex md:gn-flex-row gn-flex-col gn-items-start gn-mb-3" [style.color]="textColor">
        <div class="gn-w-full gn-mb-2 md:gn-mb-0 md:gn-w-1/2">
            <p class="gn-font-bold gn-uppercase gn-m-0 gn-mb-1"  *ngIf="(package?.restrictions || []).length>0">{{ 'booker.restrictions' | translate }}</p>
            <div class="gn-w-full gn-flex gn-flex-col gn-items-start">
                <ng-container  *ngFor="let restriction of (package?.restrictions || [])">
                    <p class="gn-m-0 gn-text-base" *ngIf="restriction.name !== 'Group'">
                        <fa-icon *ngIf="restriction.icon" [fixedWidth]="true" [icon]="restriction.icon"></fa-icon> {{ restriction.name }}: {{ restriction.descriptor }}
                    </p>
                    <p
                        class="gn-m-0 gn-text-base gn-text-red-500 gn-font-medium"
                        [tooltip-class]="'booking-tooltip'"
                        [tooltip]="'This package requires a min group size of ' + (session?.min_pax ?? package.min_pax) + '. If you have been invited / part of an existing group booked in for this session then paying individually in smaller numbers is fine'"
                        *ngIf="restriction.name === 'Group'"
                    >
                        <fa-icon *ngIf="restriction.icon" [fixedWidth]="true" [icon]="restriction.icon"></fa-icon> {{ restriction.name }}: {{session?.min_pax != 1 ? restriction.descriptor : (session?.max_pax??0 > 0 ?'Up to '+session.max_pax: '-')}}
                    </p>
                </ng-container>
            </div>
        </div>
        <div class="gn-w-full md:gn-w-1/2" *ngIf="package.includes && package.includes.length">
            <p class="gn-font-bold gn-uppercase gn-m-0 gn-mb-1">{{ 'booker.includes' | translate }}</p>
            <p *ngFor="let include of package.includes" class="gn-m-0 gn-mt-1 gn-text-base">– {{ include.name }}{{ include.descriptor ? ':' + include.descriptor :'' }}</p>
        </div>
    </div>
    <div class="gn-w-full gn-flex gn-items-start gn-flex-wrap">
        <div class="gn-w-full md:gn-w-1/2 gn-mb-2" *ngFor="let opt of session?.price_options; index as i" [class.md:gn-pr-1]="i % 2 === 0" [class.md:gn-pl-1]="i % 2 !== 0">
            <div class="gn-bg-white gn-items-center gn-rounded gn-border gn-border-solid gn-border-gray-300 gn-p-2">
                <p class="gn-m-0 gn-text-base gn-text-gray-700">
                    {{ opt.name }} <span class="gn-opacity-75 gn-text-sm" *ngIf="opt.label">({{ opt.label }})</span>
                </p>
                <div class="gn-w-full gn-flex gn-items-center gn-justify-between">
                    <div class="gn-flex gn-items-center gn-justify-center gn-mr-4">
                        <button class="gn-border-0 gn-text-white gn-text-center gn-bg-red-700 gn-cursor-pointer gn-w-6 gn-h-6 gn-text-xs" type="button" (click)="changeOption(opt, -1)">
                            <fa-icon [icon]="faMinus"></fa-icon>
                        </button>
                        <div class="gn-border-0 gn-w-6 gn-h-6 gn-text-center gn-m-0 gn-text-base gn-text-gray-700 gn-bg-gray-300 gn-font-semibold">{{ opt.qty_requested }}</div>
                        <button class="gn-border-0 gn-text-white gn-text-center gn-bg-green-700 gn-cursor-pointer gn-w-6 gn-h-6 gn-text-xs" type="button" (click)="changeOption(opt, 1)">
                            <fa-icon [icon]="faPlus"></fa-icon>
                        </button>
                    </div>
                    <p class="gn-flex-1 gn-m-0 gn-text-base gn-text-gray-700"><span  *ngIf="morePaxNeeded > 0 || people == 0">from </span>{{ opt.price | currency:venue?.currency }}pp</p>
                    <span *ngIf="morePaxNeeded > 0" class="gn-text-red-500 gn-mr-2">{{morePaxNeeded}} more needed</span>
                    <p class="gn-flex-1 gn-m-0 gn-text-base gn-text-gray-700" *ngIf="morePaxNeeded == 0 && people > 0">{{ (opt.price * opt.qty_requested) | currency:venue?.currency }}</p>
                </div>
            </div>
        </div>
    </div>
    <div class="gn-w-full gn-flex gn-items-start gn-flex-wrap" *ngIf="extras.length>0">
        <p class="gn-w-full gn-font-bold gn-uppercase gn-m-0 gn-mb-1">Extras</p>
        <div class="gn-w-full md:gn-w-1/2 gn-mb-2" *ngFor="let extra of extras; index as i" [class.md:gn-pr-1]="i % 2 === 0" [class.md:gn-pl-1]="i % 2 !== 0">
            <div class="gn-bg-white gn-items-center gn-rounded gn-border gn-border-solid gn-border-gray-300 gn-p-2">
                <p class="gn-m-0 gn-text-base gn-text-gray-700">
                    
                    {{ extra.name }}
                     <span        tooltipClass="note-tooltip" *nIf="extra?.desc"
                            placement="right"
                            [tooltip]="extra.desc" class="gn-mr-4 gn-aspect-square gn-rounded-full gn-bg-blue-300 gn-text-center gn-px-2">i</span>
                </p>
                <div class="gn-w-full gn-flex gn-items-center gn-justify-between">
                    <div class="gn-flex gn-items-center gn-justify-center gn-mr-4">
                        <button class="gn-border-0 gn-text-white gn-text-center gn-bg-red-700 gn-cursor-pointer gn-w-6 gn-h-6 gn-text-xs" type="button" (click)="changeExtra(extra, -1)">
                            <fa-icon [icon]="faMinus"></fa-icon>
                        </button>
                        <div class="gn-border-0 gn-w-6 gn-h-6 gn-text-center gn-m-0 gn-text-base gn-text-gray-700 gn-bg-gray-300 gn-font-semibold">{{ extra.qty_requested ?? 0 }}</div>
                        <button class="gn-border-0 gn-text-white gn-text-center gn-bg-green-700 gn-cursor-pointer gn-w-6 gn-h-6 gn-text-xs" type="button" (click)="changeExtra(extra, 1)">
                            <fa-icon [icon]="faPlus"></fa-icon>
                        </button>
                    </div>
                    <p class="gn-flex-1 gn-m-0 gn-text-base gn-text-gray-700">{{ extra.price | currency:venue?.currency }}pp</p>
                    <p class="gn-flex-1 gn-m-0 gn-text-base gn-text-gray-700">{{ (extra.price * (extra.qty_requested??0)) | currency:venue?.currency }}</p>
                </div>
            </div>
        </div>
    </div>
    <div class="gn-w-full gn-mb-2 gn-flex md:gn-flex-row gn-flex-col">
        <div class="gn-w-full md:gn-w-1/2 md:gn-pr-1 gn-mb-2 md:gn-mb-0">
            <div class="gn-w-full gn-relative">
                <input formControlName="first_name" class="gn-rounded gn-bg-white gn-font-normal gn-w-full gn-p-2 gn-border gn-border-solid gn-border-gray-300 gn-text-gray-700" placeholder="First Name*" required />
                <p class="gn-leading-none gn-text-gray-400 gn-absolute gn-lock" *ngIf="form.get('first_name').disabled">
                    <fa-icon [icon]="faLock"></fa-icon>
                </p>
            </div>
        </div>
        <div class="gn-w-full md:gn-w-1/2 md:gn-pl-1">
            <div class="gn-w-full gn-relative">
                <input formControlName="last_name" class="gn-rounded gn-bg-white gn-font-normal gn-w-full gn-p-2 gn-border gn-border-solid gn-border-gray-300 gn-text-gray-700" placeholder="Last Name" />
                <p class="gn-leading-none gn-text-gray-400 gn-absolute gn-lock" *ngIf="form.get('last_name').disabled">
                    <fa-icon [icon]="faLock"></fa-icon>
                </p>
            </div>
        </div>
    </div>
    <div class="gn-w-full gn-mb-2 gn-flex md:gn-flex-row gn-flex-col">
        <div class="gn-w-full md:gn-w-1/2 md:gn-pr-1 gn-mb-2 md:gn-mb-0">
            <div class="gn-w-full gn-relative">
                <input formControlName="customer_email" class="gn-rounded gn-bg-white gn-font-normal gn-w-full gn-p-2 gn-border gn-border-solid gn-border-gray-300 gn-text-gray-700" placeholder="{{ 'customer_data.email' | translate }}*" email required />
                <p class="gn-leading-none gn-text-gray-400 gn-absolute gn-lock" *ngIf="form.get('customer_email').disabled">
                    <fa-icon [icon]="faLock"></fa-icon>
                </p>
            </div>
        </div>
        <div class="gn-w-full md:gn-w-1/2 md:gn-pl-1">
            <div class="gn-w-full gn-relative">
                <input formControlName="customer_tel" class="gn-rounded gn-bg-white gn-font-normal gn-w-full gn-p-2 gn-border gn-border-solid gn-border-gray-300 gn-text-gray-700" placeholder="{{ 'customer_data.phone' | translate }}*" required />
                <p class="gn-leading-none gn-text-gray-400 gn-absolute gn-lock" *ngIf="form.get('customer_tel').disabled">
                    <fa-icon [icon]="faLock"></fa-icon>
                </p>
            </div>
        </div>
    </div>
    <div class="gn-w-full gn-mb-2 gn-flex md:gn-flex-row gn-flex-col">
        <div class="gn-w-full md:gn-w-1/2 md:gn-pr-1 gn-mb-2 md:gn-mb-0">
            <input formControlName="title" class="gn-rounded gn-bg-white gn-font-normal gn-w-full gn-p-2 gn-border gn-border-solid gn-border-gray-300 gn-text-gray-700" placeholder="{{ 'customer_data.event_name' | translate }}" />
        </div>
        <div class="gn-w-full md:gn-w-1/2 md:gn-pl-1">
            <ng-select formControlName="group_type" [items]="groupTypes" placeholder="Group Type"
            bindLabel="text"
            bindValue="id"></ng-select>
        </div>
    </div>
    <div class="gn-w-full gn-flex gn-justify-end gn-items-end">
        <textarea formControlName="message" class="gn-rounded gn-bg-white gn-font-normal gn-w-full gn-p-2 
        gn-resize-none gn-border gn-border-solid gn-border-gray-300 gn-text-gray-700" placeholder="{{ 'customer_data.message' | translate }}"
        *ngIf="!chatbotFeature || form.get('message').value!=''">

        </textarea>
        <div class="gn-flex gn-justify-end gn-h-12 gn-pl-2 gn-right-0 gn-relative" *ngIf="chatbotFeature">
            <geronigo-chatbot #chatbotEl
            api-url="https://apistage.geronigo.com/api/v1.1/"
            app-key="{{appKey}}" api-key="{{apiKey}}"
            brand-id="{{brandId}}"
            class="geronigo-widget"
            lang="en" form="inline"
            question-text="Found what you are looking for?"
            button-text="FAQs"
            mode="live"
            ></geronigo-chatbot>
        </div>
    </div>
    <label class="mt-2" [style.color]="textColor">
        <input type="checkbox" [style.color]="textColor" formControlName="subscribed" /> Subscribe to our newsletter
    </label>
    <div class="gn-w-full gn-flex gn-items-center gn-justify-between gn-mt-4">
        <a href="javascript:void(0)" [style.color]="textColor" class="gn-text-base" (click)="updateField.emit({ field: 'step', value: 'booking' })">
            <fa-icon [icon]="faChevronLeft"></fa-icon> {{ 'buttons.back' | translate }}
        </a>
        <div class="gn-flex gn-items-center gn-text-white">
            <button [disabled]="form.invalid || loading || people <= 0" (click)="enquiry()" [style.background]="accentColor" class="gn-uppercase gn-rounded gn-text-md gn-font-semibold gn-px-4 gn-py-3 hover:gn-opacity-75 gn-mr-2" type="button">
                <span *ngIf="!loading" class="gn-text">{{ 'buttons.enquire' | translate }}</span>
                <span *ngIf="!loading" class="gn-hint">{{ (hint || 'hints.add_participants') | translate }}</span>
                <div class="gn-spinner-loader gn-spinner-inverse" *ngIf="loading">
                    <div class="gn-bounce1"></div>
                    <div class="gn-bounce2"></div>
                    <div class="gn-bounce3"></div>
                </div>
            </button>
            <button [disabled]="(form.invalid || loading || people <= 0 || morePaxNeeded > 0)" [style.background]="primaryColor" class="gn-uppercase gn-rounded gn-text-md gn-font-semibold gn-px-4 gn-py-3 hover:gn-opacity-75" type="submit">
                <span *ngIf="!loading" class="gn-text">{{ 'buttons.book_now' | translate }}</span>
                <span *ngIf="!loading" class="gn-hint">{{ (hint || 'hints.add_participants') | translate }}</span>
                <div class="gn-spinner-loader gn-spinner-inverse" *ngIf="loading">
                    <div class="gn-bounce1"></div>
                    <div class="gn-bounce2"></div>
                    <div class="gn-bounce3"></div>
                </div>
            </button>
        </div>
    </div>
</form>
